import React from 'react'
import {Link} from 'gatsby'
import PropTypes from 'prop-types';

const Footer = ({layout = 'l-container--indented-constrained', showCta = true}) => {
    const cta = () => {
        if(!showCta) return '';

        return (
            <div className={"c-footer--pre l-flex l-flex--column l-flex--center-middle"}>

                <span className={"copy-subheader copy-subheader--tertiary"}>Before you go</span>

                <span className={"copy-header copy-header--l"}>
                            Got a project in mind?
                        </span>

                <p><Link to={"/contact"}>Get in touch</Link> for a quick quote or chat. I'll be back in touch within 48
                    hours.</p>
            </div>
        )
    };

    return (
        <footer className={"c-footer"}>
            {cta()}

            <div className="c-footer--bottom">
                <div className={"l-container " + layout}>
                    <div className="l-flex l-flex--space-between">
                        <p>&copy; John Richardson Development.</p>

                        <div className="l-flex">
                            <Link to="/about">About</Link> /
                            <Link to="/contact">Contact</Link> /
                            <Link to="/services">Services</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    layout: PropTypes.string
}

export default Footer
