import {Link} from "gatsby";
import React from "react";

const Logo = ({cb}) => {
    return (
        <Link to="/"
              onClick={() => cb()}
              className="c-logo no-border"
              title="John Richardson">
            <span>J</span>
            <span>R</span>
        </Link>
    )
}

export default Logo;
