import React from 'react'
import { Link } from 'gatsby'
import PropTypes from "prop-types";
import Logo from "./Logo";

import '../styles/components/navbar.scss'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      menuOpen:false,
      navBarActiveClass: '',
      stickyHeader:false,
    }
  }

  scrollHandler = (event) => {
    if(window.scrollY > 96) {
      this.setState({stickyHeader : true});
    } else {
      this.setState({stickyHeader : false});
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollHandler, {passive: true});
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  toggleHamburger = () => {
    if (!this.state.active) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  closeMenu = () => {
    this.setState({active: false},
        () => {
          // set the class in state for the navbar accordingly
          this.state.active
              ? this.setState({navBarActiveClass: 'is-active',})
              : this.setState({navBarActiveClass: '',})

          this.props.onToggle(this.state.active);
        }
    )
  }

  openMenu = () => {
    this.setState({active: true},
        () => {
          // set the class in state for the navbar accordingly
          this.state.active
              ? this.setState({navBarActiveClass: 'is-active',})
              : this.setState({navBarActiveClass: '',})

          this.props.onToggle(this.state.active);
        }
    )
  }

  showNavbar = (show) => {
    this.setState({stickyHeader: show});
  }

  getWrapClasses() {
    let classes = "c-navbar-wrap";

    if (this.props.visible === false && this.state.stickyHeader === false) {
      classes += " hidden";
    }

    return classes;
  }

  getClasses() {
    let classes = "c-navbar l-flex ";

    if (this.state.stickyHeader) {
      classes += "headhesive";
    }

    return classes;
  }

  render() {
    return (
      <div className={this.getWrapClasses()}>
        <nav className={this.getClasses()}
             role="navigation"
             aria-label="navigation-bar">
          <div className="l-container l-flex l-flex--space-between l-flex--stretch l-flex--middle">
            <div className="navbar-brand">
              <Logo cb={this.closeMenu}></Logo>
            </div>

            <div className={`c-navbar-burger ${this.state.navBarActiveClass}`}
                 aria-label="open-navigation"
                 role="navigation"
                 data-target="navMenu"
                 onClick={() => this.toggleHamburger()}>
              <span />
              <span />
              <span />
            </div>
          </div>
        </nav>

        <div id="navMenu"
             className={`c-navbar-nav-menu ${this.state.navBarActiveClass}`}>
          <div className={"l-flex l-flex--center-middle l-flex--column l-flex--stretch"}>
            <Link className="copy-header copy-header--xl" to="/about">
              About
            </Link>
            <Link className="copy-header copy-header--xl" to="/services">
              Services
            </Link>
            <Link className="copy-header copy-header--xl" to="/blog">
              Blog
            </Link>
            <Link className="copy-header copy-header--xl" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

Navbar.defaultProps = {
  visible: true
}

Navbar.propTypes = {
  onToggle: PropTypes.func,
  visible: PropTypes.bool
}


export default Navbar
